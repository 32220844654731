@import '../../styles/variables';

@font-face {
  font-family: 'pass';
  font-style: normal;
  font-weight: 400;
  src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAATsAA8AAAAAB2QAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAcg9+z70dERUYAAAF0AAAAHAAAAB4AJwANT1MvMgAAAZAAAAA/AAAAYH7AkBhjbWFwAAAB0AAAAFkAAAFqZowMx2N2dCAAAAIsAAAABAAAAAQAIgKIZ2FzcAAAAjAAAAAIAAAACAAAABBnbHlmAAACOAAAALkAAAE0MwNYJ2hlYWQAAAL0AAAAMAAAADYPA2KgaGhlYQAAAyQAAAAeAAAAJAU+ATJobXR4AAADRAAAABwAAAAcCPoA6mxvY2EAAANgAAAAEAAAABAA5gFMbWF4cAAAA3AAAAAaAAAAIAAKAE9uYW1lAAADjAAAARYAAAIgB4hZ03Bvc3QAAASkAAAAPgAAAE5Ojr8ld2ViZgAABOQAAAAGAAAABuK7WtIAAAABAAAAANXulPUAAAAA1viLwQAAAADW+JM4eNpjYGRgYOABYjEgZmJgBEI2IGYB8xgAA+AANXjaY2BifMg4gYGVgYVBAwOeYEAFjMgcp8yiFAYHBl7VP8wx/94wpDDHMIoo2DP8B8kx2TLHACkFBkYA8/IL3QB42mNgYGBmgGAZBkYGEEgB8hjBfBYGDyDNx8DBwMTABmTxMigoKKmeV/3z/z9YJTKf8f/X/4/vP7pldosLag4SYATqhgkyMgEJJnQFECcMOGChndEAfOwRuAAAAAAiAogAAQAB//8AD3jaY2BiUGJgYDRiWsXAzMDOoLeRkUHfZhM7C8Nbo41srHdsNjEzAZkMG5lBwqwg4U3sbIx/bDYxgsSNBRUF1Y0FlZUYBd6dOcO06m+YElMa0DiGJIZUxjuM9xjkGRhU2djZlJXU1UDQ1MTcDASNjcTFQFBUBGjYEkkVMJCU4gcCKRTeHCk+fn4+KSllsJiUJEhMUgrMUQbZk8bgz/iA8SRR9qzAY087FjEYD2QPDDAzMFgyAwC39TCRAAAAeNpjYGRgYADid/fqneL5bb4yyLMwgMC1H90HIfRkCxDN+IBpFZDiYGAC8QBbSwuceNpjYGRgYI7594aBgcmOAQgYHzAwMqACdgBbWQN0AAABdgAiAAAAAAAAAAABFAAAAj4AYgI+AGYB9AAAAAAAKgAqACoAKgBeAJIAmnjaY2BkYGBgZ1BgYGIAAUYGBNADEQAFQQBaAAB42o2PwUrDQBCGvzVV9GAQDx485exBY1CU3PQgVgIFI9prlVqDwcZNC/oSPoKP4HNUfQLfxYN/NytCe5GwO9/88+/MBAh5I8C0VoAtnYYNa8oaXpAn9RxIP/XcIqLreZENnjwvyfPieVVdXj2H7DHxPJH/2/M7sVn3/MGyOfb8SWjOGv4K2DRdctpkmtqhos+D6ISh4kiUUXDj1Fr3Bc/Oc0vPqec6A8aUyu1cdTaPZvyXyqz6Fm5axC7bxHOv/r/dnbSRXCk7+mpVrOqVtFqdp3NKxaHUgeod9cm40rtrzfrt2OyQa8fppCO9tk7d1x0rpiQcuDuRkjjtkHt16ctbuf/radZY52/PnEcphXpZOcofiEZNcQAAeNpjYGIAg///GBgZsAF2BgZGJkZmBmaGdkYWRla29JzKggxD9tK8TAMDAxc2D0MLU2NjENfI1M0ZACUXCrsAAAABWtLiugAA)
    format('woff');
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: url('../../images/kcar.jpg') no-repeat 100% 14%;
  background-size: 100%;

  .form {
    box-sizing: border-box;
    z-index: 1;
    width: 240px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    transform: translateY(-60px);

    .logoWrapper {
      display: flex;
      justify-content: center;
      .logo {
        object-fit: contain;
        width: 150px;
      }
    }

    .idInput {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      border-bottom: 1px solid #e9edf4;
      font-size: 16px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      border-bottom: 1px solid $hidden;
    }

    .passwordInput {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      font-family: 'pass', 'Roboto', Helvetica, Arial, sans-serif;
      font-size: 16px;
      &::-webkit-input-placeholder {
        transform-origin: 0 50%;
      }
      &::-moz-placeholder {
        font-size: 14px;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        font-size: 14px;
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      .submit {
        width: 100%;
        border-radius: 4px;
        color: #fff;
        border: none;
        height: 56px;
        background: #396eff;
        position: relative;
        padding: 10px;
        font-size: 14px;
        box-shadow: 0 3px 6px rgba(103, 157, 255, 0.2);
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
          box-shadow: 0 6px 12px rgba(103, 157, 255, 0.4);
        }
      }
    }
  }
}
